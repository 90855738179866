import { get, find, flatMap, identity, includes, isEmpty, pickBy, sortBy, values } from 'lodash';

export const isLoading = (state, clubId) => {
  const loadingClubs = get(state, 'information.loadingClubs', []);
  return includes(loadingClubs, clubId);
};

export const getPageData = (state, clubId) => get(state, `information.pageData[${clubId}]`);

export const getArticles = (state, clubId) => get(getPageData(state, clubId), 'articles', []);

export const getDocumentGroups = (state, clubId) =>
  get(getPageData(state, clubId), 'documentGroups', []);

export const getArticle = (state, clubId, articleId) => {
  const article = find(getArticles(state, clubId), { id: articleId });
  const newArticle = { ...article };
  if (isEmpty(newArticle.pages)) {
    newArticle.pages = [];
    return newArticle;
  }

  newArticle.pages = newArticle.pages.map((page) => {
    const newPage = { ...page };
    if (!isEmpty(newPage.documents)) {
      const docsWithInfo = newPage.documents.map((documentId) => {
        const documentGroups = flatMap(getDocumentGroups(state, clubId), (value) =>
          get(value, 'documents', []),
        );
        return find(documentGroups, { id: documentId });
      });
      newPage.documents = values(sortBy(pickBy(docsWithInfo, identity), ['rank']));
    }
    return newPage;
  });

  return newArticle;
};

export const getLinkGroups = (state, clubId) => get(getPageData(state, clubId), 'links', []);

export const getPolicies = (state, clubId) => get(getPageData(state, clubId), 'policies', []);

export const getSponsors = (state, clubId) => get(getPageData(state, clubId), 'sponsors', []);

export const hasLoadedPageData = (state, clubId) => {
  const loadingClubs = get(state, 'information.hasLoaded', []);
  return includes(loadingClubs, clubId);
};
