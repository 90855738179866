import React from 'react';
import {
  Cushion,
  FlexContainer,
  Grid,
  MaxWidth,
  ShimmerIcon,
  ShimmerTypography,
} from '@pitchero/react-ui';
import { range } from 'lodash';

const InformationHomepageLoading = () => (
  <MaxWidth component="div" maxWidth={980} center>
    <Cushion all="xxxlarge" responsive={[{ maxWidth: 'tab', props: { all: 'large' } }]}>
      <Grid
        className="ie-grid-two-col"
        columns="repeat(auto-fill, minmax(280px, 1fr))"
        columnGap="large"
        rowGap="xlarge"
        responsive={[
          {
            maxWidth: 'tab',
            props: {
              rowGap: 'large',
              columns: 'repeat(auto-fill, minmax(400px, 1fr))',
            },
          },
        ]}
      >
        {range(0, 6).map((i) => (
          <FlexContainer key={i}>
            <Cushion right="small" component="div">
              <ShimmerIcon size={24} />
            </Cushion>
            <ShimmerTypography preset="subtitle--large" />
          </FlexContainer>
        ))}
      </Grid>
    </Cushion>
  </MaxWidth>
);

export default InformationHomepageLoading;
