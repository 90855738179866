import React from 'react';
import PropTypes from 'prop-types';

const Nl2Br = ({ children }) =>
  String(children || '')
    .split('\n')
    .map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));

Nl2Br.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Nl2Br;
