import React from 'react';
import PropTypes from 'prop-types';
import useBreakpoint from 'lib/use-breakpoint';
import { Cushion } from '@pitchero/react-ui';
import SlashNavigation from '../navigation/mobile/slash-navigation';
import SecondaryNavigationLinks from '../navigation/secondary-navigation-links';
import SecondaryNavigationContainer from '../navigation/secondary-navigation-container';

const links = [
  {
    name: 'Information',
    route: 'information.index',
    params: {},
    transKey: 'information:information_title',
  },
  {
    name: 'Links',
    route: 'information.links',
    params: {},
    transKey: 'information:links_title',
  },
  {
    name: 'Documents',
    route: 'information.documents',
    params: {},
    transKey: 'information:documents_title',
  },
  {
    name: 'Sponsors',
    route: 'information.sponsors',
    params: {},
    transKey: 'information:sponsors_title',
  },
  {
    name: 'Policies',
    route: 'information.policies',
    params: {},
    transKey: 'information:policies_title',
  },
];

const InfoSubNav = ({ sectionName, sectionTransKey }) => {
  const isMobile = useBreakpoint();

  if (isMobile) {
    return (
      <SlashNavigation
        name="Club"
        nameTransKey="common:club"
        activeSection={sectionName}
        activeSectionTransKey={sectionTransKey}
        items={links}
      />
    );
  }

  return (
    <SecondaryNavigationContainer title="Club" fullWidth={false}>
      <Cushion left={32} component="div">
        <SecondaryNavigationLinks links={links} activeSection={sectionName} />
      </Cushion>
    </SecondaryNavigationContainer>
  );
};

InfoSubNav.propTypes = {
  sectionName: PropTypes.string.isRequired,
  sectionTransKey: PropTypes.string.isRequired,
};

export default InfoSubNav;
