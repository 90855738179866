import PropTypes from 'prop-types';

export const DOCUMENT_PROPTYPES = PropTypes.shape({
  fileSize: PropTypes.string,
  fileType: PropTypes.string,
  id: PropTypes.number,
  private: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
});

export const DOCUMENTS_PROPTYPES = PropTypes.arrayOf(DOCUMENT_PROPTYPES);
