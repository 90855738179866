import { Cushion, Space, Typography } from '@pitchero/react-ui';
import Article from 'components/article';
import ArticleLoadingPlaceHolder from 'components/article/loading-placeholder';
import NoBackground from 'components/club-frame/header/no-background';
import SeoHead from 'components/seo';
import { Trans, withTranslation } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InfoSubNav from './info-sub-nav';
import TwoPaneFrame from './two-pane-frame';

const InformationPolices = ({ isLoading, policies, club, t }) => {
  const [activePolicy, setActivePolicy] = useState(get(policies, '[0].type'));
  const article = isLoading ? (
    <ArticleLoadingPlaceHolder noImage />
  ) : (
    <Article
      title={t('information:policies_title')}
      club={club}
      noImage
      style={{ padding: '20px', boxSizing: 'border-box' }}
    >
      {policies.map((policy) => (
        <Space bottom="medium">
          <div key={`${policy.type}_body`} id={policy.type}>
            <Typography preset="staticSubtitle--large">
              {t(`information:${policy.type}_title`)}
            </Typography>
            <div className="bbcode-content" dangerouslySetInnerHTML={{ __html: policy.policy }} />
          </div>
        </Space>
      ))}
    </Article>
  );

  if (policies.length < 2) {
    return (
      <>
        <SeoHead
          club={club}
          route="information.policies"
          titleTranslationKey="information:policies_title"
        />
        <InfoSubNav sectionName="Policies" sectionTransKey="information:policies_title" />
        <Cushion vertical="xlarge">{article}</Cushion>
      </>
    );
  }

  return (
    <>
      <SeoHead
        club={club}
        route="information.policies"
        titleTranslationKey="information:policies_title"
      />
      <NoBackground />
      <InfoSubNav sectionName="Policies" sectionTransKey="information:policies_title" />
      <TwoPaneFrame
        openPanelLabel="information:policies_open_panel"
        title={<Trans i18nKey="information:policies_title">Policies</Trans>}
        navItems={policies.map(({ type }) => ({
          id: type,
          href: `#${type}`,
          label: t(`information:${type}_title`),
          isActive: activePolicy === type,
          onClick: () => setActivePolicy(type),
        }))}
      >
        {article}
      </TwoPaneFrame>
    </>
  );
};

InformationPolices.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  isLoading: PropTypes.bool.isRequired,
  policies: PropTypes.arrayOf(
    PropTypes.shape({
      policy: PropTypes.string,
      type: PropTypes.string,
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'information'])(InformationPolices);
