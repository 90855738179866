import {
  Cushion,
  FlexContainer,
  Grid,
  MaxWidth,
  Rectangle,
  Shadow,
  Typography,
} from '@pitchero/react-ui';
import RosBottomBanner from 'components/adverts/ros-bottom-banner';
import RosTopBanner from 'components/adverts/ros-top-banner';
import NoBackground from 'components/club-frame/header/no-background';
import ClubLink from 'components/club-link';
import IconWithTheme from 'components/icon-with-theme';
import SeoHead from 'components/seo';
import FolderIcon from 'icons/Folder';
import LinkIcon from 'icons/Link';
import PageIcon from 'icons/Page';
import SheetImportantIcon from 'icons/SheetImportant';
import UserCircleIcon from 'icons/UserCircle';
import { Trans } from 'lib/i18n';
import CLUB_PROP_TYPES from 'lib/prop-types/club';
import { map, sumBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import InformationHomepageLoading from './homepage-loading';
import InfoSubNav from './info-sub-nav';

const InformationHomepage = ({
  articles,
  club,
  documents,
  isLoading,
  links,
  policies,
  sponsors,
  linksEnabled,
  documentsEnabled,
  sponsorsEnabled,
}) => {
  const renderIconWithLabel = (IconComponent, label, note = null) => (
    <FlexContainer alignItems="flex-start">
      <IconWithTheme>
        <IconComponent color="primary" size={24} />
      </IconWithTheme>
      <Cushion left="small" responsive={[{ maxWidth: 'tab', props: { top: 'xxsmall' } }]}>
        <Typography preset="subtitle--large">{label}</Typography>
      </Cushion>
      {note && (
        <Cushion left="small">
          <Typography
            preset="tag--large"
            color="dustygrey"
            lineHeight="24px"
            responsive={[{ maxWidth: 'tab', props: { lineHeight: '18px' } }]}
          >
            {note}
          </Typography>
        </Cushion>
      )}
    </FlexContainer>
  );

  return (
    <>
      <SeoHead
        club={club}
        descriptionTranslationKey="information:information_meta_description"
        route="information.index"
        titleTranslationKey="information:information_page_title"
      />
      <NoBackground />
      <InfoSubNav sectionName="Information" sectionTransKey="information:information_title" />
      <Cushion
        horizontal="large"
        vertical="medium"
        responsive={[{ maxWidth: 'tab', props: { horizontal: 'small' } }]}
        component="div"
      >
        <Cushion bottom="medium">
          <RosTopBanner />
        </Cushion>

        {isLoading ? (
          <InformationHomepageLoading />
        ) : (
          <Shadow>
            <MaxWidth component="div" maxWidth={980} center>
              <Rectangle fill="white" radius="rounded">
                <Cushion all="xxxlarge" responsive={[{ maxWidth: 'tab', props: { all: 'large' } }]}>
                  <Grid
                    className="ie-grid-two-col"
                    columns="1fr 1fr"
                    columnGap="large"
                    rowGap="xlarge"
                    responsive={[
                      {
                        maxWidth: 'tab',
                        props: {
                          rowGap: 'large',
                          columns: '1fr',
                        },
                      },
                    ]}
                  >
                    {map(articles, (article) => {
                      return (
                        <div key={article.id}>
                          <ClubLink
                            route="information.article"
                            params={{ _name: article.name, articleId: article.id }}
                          >
                            <a>{renderIconWithLabel(PageIcon, article.name)}</a>
                          </ClubLink>
                        </div>
                      );
                    })}

                    {documentsEnabled && documents.length > 0 && (
                      <Rectangle>
                        <ClubLink route="information.documents">
                          <a>
                            {renderIconWithLabel(
                              FolderIcon,
                              <Trans i18nKey="information:documents_title" />,
                              <Trans
                                i18nKey="information:documents"
                                values={{
                                  documentCount: sumBy(
                                    documents,
                                    (group) => group.documents.length,
                                  ),
                                }}
                              />,
                            )}
                          </a>
                        </ClubLink>
                      </Rectangle>
                    )}

                    {policies.length > 0 && (
                      <Rectangle>
                        <ClubLink route="information.policies">
                          <a>
                            {renderIconWithLabel(
                              SheetImportantIcon,
                              <Trans i18nKey="information:policies_title" />,
                            )}
                          </a>
                        </ClubLink>
                      </Rectangle>
                    )}

                    {linksEnabled && links.length > 0 && (
                      <Rectangle>
                        <ClubLink route="information.links">
                          <a>
                            {renderIconWithLabel(
                              LinkIcon,
                              <Trans i18nKey="information:links_title" />,
                              <Trans
                                i18nKey="information:links"
                                values={{ linkCount: links.length }}
                              />,
                            )}
                          </a>
                        </ClubLink>
                      </Rectangle>
                    )}

                    {sponsorsEnabled && sponsors.length > 0 && (
                      <Rectangle>
                        <ClubLink route="information.sponsors">
                          <a>
                            {renderIconWithLabel(
                              UserCircleIcon,
                              <Trans i18nKey="information:sponsors_title" />,
                            )}
                          </a>
                        </ClubLink>
                      </Rectangle>
                    )}
                  </Grid>
                </Cushion>
              </Rectangle>
            </MaxWidth>
          </Shadow>
        )}

        <Cushion vertical="large">
          <RosBottomBanner />
        </Cushion>
      </Cushion>
    </>
  );
};

InformationHomepage.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      page: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          content: PropTypes.string,
          image: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  club: CLUB_PROP_TYPES.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  policies: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  sponsors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  linksEnabled: PropTypes.bool.isRequired,
  documentsEnabled: PropTypes.bool.isRequired,
  sponsorsEnabled: PropTypes.bool.isRequired,
};

export default InformationHomepage;
