import React from 'react';
import PropTypes from 'prop-types';
import {
  Cushion,
  FlexContainer,
  MaxWidth,
  Rectangle,
  RelativeContainer,
  Typography,
} from '@pitchero/react-ui';

const SecondaryNavContainer = ({ children, title, fullWidth }) => (
  <RelativeContainer>
    <Rectangle fill="#fff">
      <Cushion
        responsive={[
          {
            maxWidth: 720,
            props: { horizontal: 'medium' },
          },
        ]}
        horizontal="large"
        component="div"
      >
        <MaxWidth component="div" maxWidth={1366} center>
          <FlexContainer
            flexDirection="row"
            alignItems="center"
            justifyContent={fullWidth ? 'space-between' : 'flex-start'}
          >
            {title && (
              <Cushion top={20} bottom={19} style={{ flex: fullWidth ? 1 : '' }}>
                <Typography preset="title" color="black">
                  {title}
                </Typography>
              </Cushion>
            )}
            {children}
          </FlexContainer>
        </MaxWidth>
      </Cushion>
    </Rectangle>
  </RelativeContainer>
);

SecondaryNavContainer.defaultProps = {
  title: null,
  fullWidth: true,
};

SecondaryNavContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default SecondaryNavContainer;
