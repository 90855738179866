import PageDataLoader from 'lib/page-data/loader';
import PageKey from 'lib/page-data/key';

import {
  CLUB_INFORMATION_REQUEST,
  CLUB_INFORMATION_RECEIVE,
  CLUB_INFORMATION_FAILURE,
  PAGE_DATA_LOAD,
} from 'store/action-types';

export const fetchInformationPageData = (clubId, request) => async (dispatch) => {
  try {
    dispatch({ type: CLUB_INFORMATION_REQUEST, payload: { clubId } });
    const pageKey = new PageKey(clubId, 'information');
    const pageData = await PageDataLoader.getPageData(pageKey, request);
    dispatch({ type: CLUB_INFORMATION_RECEIVE, payload: { clubId, pageData: pageData.data } });
    dispatch({ type: PAGE_DATA_LOAD, payload: pageKey.toString() });
    return {};
  } catch (error) {
    let statusCode = 500;
    if (error) {
      statusCode = error.errorStatusCode || statusCode;
    }

    dispatch({ type: CLUB_INFORMATION_FAILURE, payload: { clubId, statusCode, error } });
    return { error };
  }
};
