import React from 'react';
import PropTypes from 'prop-types';
import { Image, Rectangle } from '@pitchero/react-ui';

const ArticleImage = ({ alt, src }) => (
  <Rectangle radius="roundedTop" borderBottom="thinLightGrey">
    <Image src={src} alt={alt} preset="headline" fullWidth />
  </Rectangle>
);

ArticleImage.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
};

export default ArticleImage;
