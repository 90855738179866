import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Grid, Rectangle, Space, Typography } from '@pitchero/react-ui';
import LockIcon from 'icons/Lock';
import IconWithTheme from 'components/icon-with-theme';
import { Trans } from 'lib/i18n';
import DocumentIcon from './document-icon';

const Document = ({ className, document, style }) => (
  <Cushion vertical="large" style={style} className={className}>
    <Grid
      className="ie-flex document-detail"
      columns="90px 1fr"
      columnGap="medium"
      alignItems="center"
    >
      <DocumentIcon clubDocument={document} />
      <Rectangle>
        <Typography preset="subtitle--small" component="h3">
          {document.private && (
            <IconWithTheme>
              <LockIcon />
            </IconWithTheme>
          )}
          {document.title}
        </Typography>
        <Space vertical="small">
          <Typography component="div" preset="body--small" color="dustygrey">
            {document.fileSize}
          </Typography>
        </Space>
        <Rectangle>
          <Typography
            component="a"
            color="primary"
            href={document.url}
            preset="name"
            style={{ cursor: 'pointer' }}
          >
            <Trans i18nKey="common:download">Download</Trans>
          </Typography>
        </Rectangle>
      </Rectangle>
    </Grid>
  </Cushion>
);

Document.defaultProps = {
  className: null,
  style: {},
};

Document.propTypes = {
  className: PropTypes.string,
  document: PropTypes.shape({
    fileSize: PropTypes.string,
    private: PropTypes.bool,
    title: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  style: PropTypes.shape(),
};
export default Document;
